<template>
  <div class="shopDetail">
    <div class="shop-item flex">
      <div class="shop-item-l">
        <swiper :banners="banners"></swiper>
      </div>
      <div v-if="clientWidth < 750" class="shop-item-num-mb flex6">
        <div class="shop-item-num-price flex2 co-fff">
          <img src="@/assets/PC/shop/money.png" class="mr" alt="">
          {{ shopDetails.price }}
          <span v-if="shopDetails.isDiscount" class="co-fff mr"
            style="text-decoration: line-through;margin-left: 15px; font-size: 12px">[{{ shopDetails.oldPrice }}]</span>
          <b v-if="shopDetails.isDiscount" class="ml co-ff0">{{ Discount(shopDetails.oldPrice, shopDetails.price) }}</b>
        </div>
        <div class="shop-item-num-ad flex2">
          <div class="flex0" @click="subNum">-</div>
          <input v-if="shopDetails.onlyBuyOne || shopDetails.residueNum == 0" type="text" class="tc" v-model="shopNum"
            disabled>
          <input v-else type="text" class="tc" v-model.number="shopNum" @change="numChange">
          <div class="flex0" v-if="shopDetails.residueNum == 0">+</div>
          <div class="flex0" v-else @click="addNum">+</div>
        </div>
      </div>
      <div class="shop-item-r">
        <h1>{{ shopDetails.title }}</h1>
        <div class="shop-item-price flex2">
          <span class="co-999">价格：</span>
          <img src="@/assets/PC/profile/gold.png" class="mr">
          <span class="violet mr">{{ shopDetails.price }}</span>
          <span v-if="shopDetails.isDiscount" class="co-999 mr"
            style="text-decoration: line-through; font-size: 12px">[{{ shopDetails.oldPrice }}]</span>
          <b v-if="shopDetails.isDiscount" class="ml co-ff0">{{ Discount(shopDetails.oldPrice, shopDetails.price) }}</b>
        </div>
        <div class="shop-item-brief flex">
          <span class="co-999" style="min-width: 48px">简介：</span>
          <span class="co-999 briefs mr">{{ shopDetails.introduction }}</span>
        </div>
        <div class="hr1"></div>
        <div class="shop-item-msg flex">
          <span class="co-999" style="min-width: 48px">提示：</span>
          <span class="violet mr">{{ shopDetails.reminder }}</span>
        </div>
        <div v-if="clientWidth > 750" class="shop-item-num flex2">
          <span class="co-999" style="min-width: 48px">数量：</span>
          <div class="flex">
            <el-input v-if="shopDetails.onlyBuyOne || shopDetails.residueNum == 0" type="text"
              class="tc shop-item-num-q" v-model="shopNum" disabled></el-input>
            <el-input v-else type="text" v-model.number="shopNum" @change="numChange"
              class="shop-item-num-q"></el-input>
            <div class="btnap">
              <div class="flex0" v-if="shopDetails.residueNum == 0">+</div>
              <div class="flex0 cur" v-else @click="addNum">+</div>
              <div class="flex0 cur" @click="subNum">-</div>
            </div>
            <div class="flex2 ml">件
              <i v-if="shopDetails.onlyBuyOne" class="co-999">（库存 {{ shopDetails.residueNum }} 个，仅限兑换 1 个）</i>
              <i v-else class="co-999">（库存 {{ shopDetails.residueNum }} 个）</i>
            </div>
          </div>
        </div>
        <div class="shop-item-btn">
          <div v-if="shopDetails.residueNum == 0">
            <el-button type="info" class="Uschange" disabled>暂无库存</el-button>
          </div>
          <div v-else>
            <el-button v-if="!shopDetails.isBuy" type="info" class="Uschange" disabled>您已兑换</el-button>
            <el-button v-else @click="ImmediatelyChange" type="info" class="Uschange">我要兑换</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="shop-intro">
      <h1 class="co-fff flex0">商品详情</h1>
      <div v-html="shopDetails.productContent" class="description">
      </div>
    </div>
    <div v-if="clientWidth < 750" class="shop-item-btn1">
      <div v-if="shopDetails.residueNum == 0">
        <el-button type="info" class="exchange" disabled>暂无库存</el-button>
      </div>
      <div v-else>
        <el-button v-if="!shopDetails.isBuy" type="info" class="exchange" disabled>您已兑换</el-button>
        <el-button v-else @click="ImmediatelyChange" type="info" class="exchange">我要兑换</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as Shop from "@/api/Shop.js";
import { dataState } from "@/api/all.js";
import swiper from "@/components/ShopSwiper/Index.vue";
import { GetWxShares } from "@/utils/common";

export default {
  name: "ShopDetail",
  data() {
    return {
      loading: true,                       // !loading状态
      shopNum: 1,                          // !选择的数量
      shopDetails: {
        onlyBuyOne: true,                  // !是否只允许兑换一次
      },                                   // !详情数据
      banners: [],                          // !商品信息图
    }
  },
  mounted() {
    const Id = this.$route.query.id;
    Shop.GetProeuctInfo({ productId: Id }).then(res => {
      if (res.status == 1) {
        this.shopDetails = res.data;
        this.banners = res.data.imgs.split(',');
        this.$emit("change", res.data.title);
        const share = {
          title: res.data.title,
          desc: ''
        }
        GetWxShares(share);
      } else {
        this.$toast(res.message);
        this.$router.push('/Home/ShoppingMall/ShopList');
      }
    });

    // 数据统计
    const data = {
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "ShopDetail",
      "relationId": this.$route.query.id,
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid', res.data);
    });
  },
  methods: {
    // !计算折扣
    Discount(oldprice, price) {
      const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let k = (price / oldprice + '').substr(0, 4);
      k = (k * 1).toFixed(1);
      let priceArr = '限时';
      if (k < 0.1) {
        priceArr += '一';
      } else if (k == 1) {
        priceArr += '九';
      } else {
        priceArr += arr1[k[2]];
      }
      priceArr += '折';
      return priceArr;
    },
    // !商品数量加减
    addNum() {
      if (this.shopDetails.onlyBuyOne) {
        this.shopNum = 1;
        this.$toast('当前商品只允许兑换一次！');
      } else {
        if (this.shopNum < this.shopDetails.residueNum) {
          this.shopNum++;
        } else {
          this.shopNum = this.shopDetails.residueNum;
          this.$toast('超出最大可兑换数量，已重置！');
        }
      }
    },
    subNum() {
      if (this.shopNum < 2) {
        this.shopNum = 1;
      } else {
        this.shopNum--;
      }
    },
    // !监听手动输入
    numChange() {
      if (!Number.isNaN(this.shopNum * 1)) {
        this.shopNum = this.shopNum.toFixed(0);
        if (this.shopDetails.onlyBuyOne) {
          this.shopNum = 1;
        } else {
          if (this.shopNum < 2 || !(this.shopNum * 1)) {
            this.shopNum = 1
          } else if (this.shopNum > this.shopDetails.residueNum) {
            this.shopNum = this.shopDetails.residueNum;
            this.$toast('超出最大可兑换数量，已重置！');
          }
        }
      }
    },
    // !去表单页面
    ImmediatelyChange() {
      if (this.$store.state.token) {
        if (Number.isNaN(this.shopNum * 1)) {
          this.shopNum = 1;
        }
        const Id = { 'productId': this.$route.query.id };
        Shop.ValidateOrder(Id).then(res => {
          if (res.status == 1) {
            this.$router.push('/Home/ShopChange?id=' + this.$route.query.id + '&num=' + this.shopNum);
          } else {
            this.loading = false;
            this.$toast(res.message);
            this.$router.push("/Home/ShoppingMall/ShopList");
          }
        })
      } else {
        this.$toast('请先登录后再来兑换吧！')
      }
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    }
  },
  components: {
    swiper
  }
}
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.shopDetail {
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
  background: #fff;
  padding: 30px;

  .shop-item-l {
    width: 350px;
    min-width: 350px;
    height: 196px;
    margin-right: 20px;
    box-shadow: 0 0 3px #ccc;
    overflow: hidden;
    border-radius: 5px;
  }

  .shop-item-r {
    width: 100%;
  }

  .shop-item-r h1 {
    font-size: 19px;
    margin-bottom: 13px;
  }

  .shop-item-num-mb {
    display: none;
  }

  .shop-item-price {
    height: 54px;
    background: #f9f9f9;
    padding-left: 30px;
  }

  .shop-item-price .co-999 {
    font-size: 16px;
  }

  .shop-item-price .violet {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    margin-top: -2px;
  }

  .shop-item-price img {
    width: 15px;
    height: 15px;
  }

  .shop-item-brief,
  .shop-item-msg,
  .shop-item-num {
    margin: 10px 0
  }

  .shop-item-brief span:first-of-type,
  .shop-item-msg span:first-of-type,
  .shop-item-num span:first-of-type {
    margin-left: 30px;
    font-size: 16px;
  }

  .shop-item-num-q {
    width: 100px;
  }

  .btnap div {
    border: 1px #ccc solid;
    width: 30px;
    height: 18px;
    margin: 0 0 4px 4px;
  }

  .shop-item-btn {
    margin: 40px 0 0 80px;
  }

  .shop-intro h1 {
    width: 137px;
    height: 40px;
    background: #b409f0;
    font-size: 18px;
    position: relative;
    margin-bottom: 31px;
  }

  .shop-intro h1::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #b409f0;
    position: absolute;
    right: -20px;
  }

  .shop-item-btn1 {
    display: none;
    position: fixed;
    bottom: -0.5px;
    left: 0;
    width: 100%;
  }

  .shop-item-btn1 button {
    border-radius: 0;
  }
}

@media (max-width: 750px) {
  .shopDetail {
    margin: 0 vw(40);
    position: relative;
    top: vw(-100);
    padding: 0;
    overflow: hidden;

    .shop-item {
      display: block;
    }

    .shop-item-num-mb {
      display: flex;
      height: vw(88);
      background: #6B18A8;
      margin-bottom: vw(20);
      line-height: vw(44);
    }

    .shop-item-num-price {
      font-size: vw(32);
      margin-left: vw(23);
    }

    .shop-item-num-price img {
      width: vw(26);
      height: vw(25);
      margin-left: vw(11);
    }

    .shop-item-num-ad {
      margin-right: vw(24);
    }

    .shop-item-num-ad div {
      width: vw(41);
      height: vw(49);
      background: #d0c4fc;
      margin: 0 vw(4);
    }

    .shop-item-num-ad input {
      width: vw(68);
      height: vw(49);
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .shop-item-l {
      width: 100%;
      min-width: 100%;
      box-shadow: none;
      border-radius: 0;
    }

    .shop-item-num,
    .shop-item-price {
      display: none;
    }

    .shop-item-r h1 {
      font-size: vw(31);
      margin: 0 vw(24);
      margin-top: 0;
      margin-bottom: vw(20);
    }

    .shop-item-brief,
    .shop-item-msg {
      margin-left: vw(24);
      font-size: vw(24);
    }

    .shop-item-msg .violet,
    .briefs {
      font-size: vw(24);
    }

    .shop-item-brief span:first-of-type,
    .shop-item-msg span:first-of-type {
      width: vw(72);
      min-width: vw(72) !important;
      font-size: vw(22);
      margin-left: 0;
    }

    .hr1 {
      height: 1px;
      background: #f2f2f2;
      margin: 0 vw(24);
    }

    .shop-item-btn {
      display: none;
    }

    .shop-intro {
      margin: 0 vw(24);
    }

    .shop-intro h1 {
      width: auto;
      height: vw(60);
      justify-content: flex-start;
      padding-left: vw(20);
      font-size: vw(28);
      margin-bottom: vw(20);
    }

    .shop-intro h1::after {
      border-bottom: 0;
      border-top: vw(60) solid #fff;
      right: vw(-30);
      border-left: vw(30) solid transparent;
      border-right: vw(30) solid transparent;
    }

    .description {
      margin-bottom: vw(60);
      font-size: vw(24);
    }

    .exchange {
      width: 100%;
      height: vw(88);
    }

    .shop-item-btn1 {
      display: block;
      position: relative;
    }
  }
}
</style>
<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.exchange.el-button--info span {
  font-size: vw(32) !important;
}
</style>