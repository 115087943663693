<template>
  <div class="ShopBanner">
    <div v-if="onlyBanner">
      <img :src="banners" alt="" width="100%" />
    </div>
    <div v-else style="height: 100%">
      <swiper  class="shop-swiper-container" ref="mySwiper" :options="swiperOption" :auto-play="true" v-if="banners.length > 0">
        <swiper-slide v-for="(item, index) in banners" :key="index" class="swiper-item">
          <img :src="item" alt="" />
        </swiper-slide>
        <!-- ← →按钮 -->
        <div class="swiper-button-prev" slot="button-prev"><i class="el-icon-arrow-left"></i></div>
        <div class="swiper-button-next" slot="button-next"><i class="el-icon-arrow-right"></i></div>
      </swiper>
    </div>
    <div class="readIndex" v-if="banners.length > 1">
      {{ readIndex }} / {{ banners.length }}
    </div>
  </div>
</template>
<script>
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    banners: {
      type: Array,
    },
  },
  data() {
    const that = this;
    return {
      onlyBanner: false, // !只有一张图时
      readIndex: 1,      // !当前照片指示
      swiperOption: {
        notNextTick: true,  
        initialSlide: 0,
        speed: 1000, //匀速时间
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: "flip",
        on: {
          slideChangeTransitionStart() {
            that.readIndex = this.realIndex + 1;
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    imgUrl(item) {
      if (item.url) {
      }
    },
  },
  watch: {
    banners: function (news) {
      if (this.banners.length == 1) {
        this.onlyBanner = true;
      }
    },
  },
};
</script>
<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.ShopBanner {
  height: 100%; position: relative;
  .shop-swiper-container,
  .swiper-wrapper,
  .swiper-item,
  .swiper-item div {
    height: 100%;
  }
  .swiper-button-prev:after, .swiper-button-next:after { content: ''; }
  .swiper-button-prev, .swiper-button-next { background: rgba(0, 0, 0, 0.5);}
  .swiper-button-prev i, .swiper-button-next i {font-size: 25px;}
  .swiper-button-prev {left: 0;}
  .swiper-button-next {right: 0;}
  img {
    width: 100%;
    height: 100%;
  }
  .readIndex {position: absolute; bottom: 10px; right: 10px; background: rgba(0, 0, 0, 0.5); z-index: 2; color: #fff; padding: 0 5px;border-radius: 9px;}
}

@media (max-width: 750px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}
</style>